<template>
    <nl-loader v-if="preloading" :show="true" />
    <div v-else>
        <div class="mb-3">
            <router-link to="/admin/spots/sites" class="text-decoration-none">
                <b-button type="is-light" size="is-small" icon-left="backward"> Retourner aux spots</b-button>
            </router-link>
        </div>
        <div class="box">
            <b-tabs
                v-model="activeTab"
                @input="
                    value => {
                        openOnce[value] = true
                    }
                "
            >
                <b-tab-item v-for="tab in tabs" :key="tab.index" :label="tab.label" :disabled="tab.disabled">
                    <SpotsOrdersTable v-if="openOnce[tab.index]" :type="tab.type" :status="tab.status" />
                </b-tab-item>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import SpotsOrdersTable from '../../components/Spots/SpotsOrdersTable.vue'

    export default {
        name: 'SpotsOrders',
        components: { SpotsOrdersTable },
        title: 'Commandes par spots',
        data() {
            return {
                counts: [],
                preloading: false,
                activeTab: 0,
                openOnce: {
                    0: true
                },

                statusToDo: 'todo',
                statusCompleted: 'completed',
                statusInProgress: 'in_progress'
            }
        },
        computed: {
            tabs() {
                return [
                    {
                        type: 'all',
                        label: 'Tous' + ` (${this.counts.reduce((accumulator, e) => accumulator + e.total, 0)})`,
                        icon: 'sitemap',
                        disabled: false,
                        index: 0
                    },
                    {
                        type: 'todo',
                        label: 'A faire' + ` (${this.formattedCounts[this.statusToDo] || 0})`,
                        icon: 'sitemap',
                        disabled: !this.formattedCounts[this.statusToDo] || this.formattedCounts[this.statusToDo] <= 0,
                        index: 1,
                        status: this.statusToDo
                    },
                    {
                        type: 'in_progress',
                        label: 'En cours' + ` (${this.formattedCounts[this.statusInProgress] || 0})`,
                        icon: 'sitemap',
                        disabled:
                            !this.formattedCounts[this.statusInProgress] ||
                            this.formattedCounts[this.statusInProgress] <= 0,
                        index: 2,
                        status: this.statusInProgress
                    },
                    {
                        type: 'completed',
                        label: 'Terminées' + ` (${this.formattedCounts[this.statusCompleted] || 0})`,
                        icon: 'sitemap',
                        disabled:
                            !this.formattedCounts[this.statusCompleted] ||
                            this.formattedCounts[this.statusCompleted] <= 0,
                        index: 3,
                        status: this.statusCompleted
                    }
                ]
            },
            formattedCounts() {
                let obj = {}
                this.counts.forEach(i => {
                    obj[i.status] = i.total
                })
                return obj
            }
        },
        created() {
            this.getData()
        },
        methods: {
            ...mapActions('spots', {
                getCount: 'countByStatus'
            }),
            getData() {
                this.getCount()
                    .then(counts => {
                        this.counts = counts
                        this.preloading = false
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
            }
        }
    }
</script>

<style scoped></style>
